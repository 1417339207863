import React, { useEffect, useState } from 'react';
import { useLottie } from "lottie-react";
import { Image } from 'react-bootstrap';
import './style.css';


// https://lottiefiles.com/free-animation/flowers-1MQHkIWS3W
import BungaAnimation from './../../../lottie/bunga.json';

const Home = () => {
          const lottieOptions = {
                    animationData: BungaAnimation,
                    loop: true,
                    autoplay: true
          };


          const { View: LottieBungaTopRight } = useLottie(lottieOptions, { width: 150, height: 150 });
          const { View: LottieBungaBottomLeft } = useLottie(lottieOptions, { width: 200, height: 200 });


          const calculateTimeLeft = () => {
                    const targetDate = new Date('2024-10-20T17:00:00Z');
                    const now = new Date();
                    const difference = targetDate - now;

                    let timeLeft = {};

                    if (difference > 0) {
                              timeLeft = {
                                        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                                        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                                        minutes: Math.floor((difference / 1000 / 60) % 60),
                                        seconds: Math.floor((difference / 1000) % 60)
                              };
                    }

                    return timeLeft;
          };

          const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

          useEffect(() => {
                    const timer = setInterval(() => {
                              setTimeLeft(calculateTimeLeft());
                    }, 1000);

                    return () => clearInterval(timer);
          }, []);


          return (
                    <div id="home" className="position-relative d-flex justify-content-center align-items-top" style={{ backgroundColor: '#e0d7ca', backgroundPosition: 'center' }}>
                              <div className="lottie position-absolute top-0 end-0">{LottieBungaTopRight}</div>
                              <div className="lottie position-absolute bottom-0 start-0">{LottieBungaBottomLeft}</div>
                              <div className="text-center">
                                        <h1 className='font-esthetic-tegak' style={{ fontSize: '25pt', marginTop: '150px' }} data-aos="fade-up">The Wedding of</h1>
                                        <div data-aos="fade-up" className="img-crop border border-3 border-light shadow-lg mb-4 mx-auto d-flex justify-content-center align-items-center" style={{}}>
                                                  <Image src={process.env.PUBLIC_URL + '/assets/img/foto.webp'} alt="Wedding Photo" />
                                        </div>
                                        <h1 data-aos="fade-up-right" className='font-esthetic' style={{ fontSize: '25pt' }}>Khisby & Sita</h1>
                                        <p data-aos="fade-up-left" className='font-color-brown' style={{ marginTop: '20px' }}>
                                                  Kami mengundang Anda untuk hadir <br /> dalam acara pernikahan kami
                                        </p>
                                        <div className="d-flex justify-content-center">
                                                  <div className="p-2" data-aos="flip-left">
                                                            <div className="p-3 shadow" style={{ border: '1px solid rgba(255, 255, 255, 0.8)', backgroundColor: 'rgba(184, 183, 165, 1)' }}>
                                                                      <h2 className='font-esthetic-tegak'>{timeLeft.days || '0'}</h2>
                                                                      <p className='font-esthetic-tegak'>Days</p>
                                                            </div>
                                                  </div>
                                                  <div className="p-2" data-aos="flip-right">
                                                            <div className="p-3 shadow" style={{ border: '1px solid rgba(255, 255, 255, 0.8)', backgroundColor: 'rgba(184, 183, 165, 1)' }}>
                                                                      <h2 className='font-esthetic-tegak'>{timeLeft.hours || '0'}</h2>
                                                                      <p className='font-esthetic-tegak'>Hours</p>
                                                            </div>
                                                  </div>
                                                  <div className="p-2" data-aos="flip-left">
                                                            <div className="p-3 shadow" style={{ border: '1px solid rgba(255, 255, 255, 0.8)', backgroundColor: 'rgba(184, 183, 165, 1)' }}>
                                                                      <h2 className='font-esthetic-tegak'>{timeLeft.minutes || '0'}</h2>
                                                                      <p className='font-esthetic-tegak'>Minutes</p>
                                                            </div>
                                                  </div>
                                                  <div className="p-2" data-aos="flip-right">
                                                            <div className="p-3 shadow" style={{ border: '1px solid rgba(255, 255, 255, 0.8)', backgroundColor: 'rgba(184, 183, 165, 1)' }}>
                                                                      <h2 className='font-esthetic-tegak'>{timeLeft.seconds || '0'}</h2>
                                                                      <p className='font-esthetic-tegak'>Seconds</p>
                                                            </div>
                                                  </div>
                                        </div>
                                        <div className="d-flex justify-content-center mt-3">
                                                  <div className="scroll-down-animation translate-middle-x">
                                                            <i className="fas fa-chevron-down"></i>
                                                  </div>
                                        </div>
                              </div>
                    </div>
          );
}

export default Home;