import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import AOS from 'aos';
import { FaEnvelopeOpen } from 'react-icons/fa6';
import './style.css';
import Typed from 'typed.js';


function Welcome({ stateBuka }) {
          const [fade, setFade] = useState(false);
          const el = React.useRef(null);

          const sendToBackend = (to) => {
                    fetch('https://wedding.khisoft.id/api/api.php', {
                              method: 'PUT',
                              headers: {
                                        'Content-Type': 'application/json',
                              },
                              body: JSON.stringify({ name: to }),
                    })
                              .then(response => {
                                        if (!response.ok) {
                                                  console.error('Network response was not ok:', response);
                                        }
                                        return response.json();
                              })
                              .then(data => {
                                        console.log('Success:', data);
                              })
                              .catch(error => {
                                        console.error('Error:', error);
                              });
          };



          const buka = async () => {
                    stateBuka(true);
                    setFade(true);
          };

          useEffect(() => {
                    AOS.init({
                              duration: 1200, // Global animation duration in milliseconds
                              easing: 'ease-in-out', // Global easing function
                              once: true, // Whether animation should happen only once
                              mirror: true, // Whether elements should animate out while scrolling past them
                    });

                    const urlParams = new URLSearchParams(window.location.search);
                    const toParam = urlParams.get('to');

                    const path = window.location.pathname;
                    const nameFromPath = path.startsWith('/to/') ? path.split('/to/')[1] : null;

                    let to = toParam ? toParam.replace(/-/g, ' ') : '';
                    if (nameFromPath) {
                              to = nameFromPath.replace(/-/g, ' ');
                    }

                    sendToBackend(to);

                    const typed = new Typed(el.current, {
                              strings: [`Welcome to Our Wedding, <br/> ${to}`],
                              typeSpeed: 40,
                              loop: true,
                              loopCount: Infinity,
                              showCursor: false,
                    });

                    return () => {
                              // Destroy Typed instance during cleanup to stop animation
                              typed.destroy();
                    };

          }, []);

          useEffect(() => {
                    if (fade) {
                              setTimeout(() => {
                                        document.getElementById('welcome').remove();
                              }, 1000);
                    }
          }, [fade]);


          return (
                    <div className={`loading-page fade-animation welcome-container ${fade ? 'fade-out-animation' : ''}`} id="welcome" style={{ opacity: 1, backgroundImage: 'url(https://wedding.khisoft.id/assets/img/piknik.webp)', }}>
                              <div className="justify-content-center align-items-start d-flex" style={{ height: '100vh', paddingTop: '130px' }} >
                                        <div className="text-center ">
                                                  <h1 ref={el} style={{ display: 'block', padding: '10px 0', minHeight: '90px' }}></h1>
                                                  <Button data-aos="fade-right" variant="light" className="shadow rounded-4 mt-1 aos-animate" onClick={buka}>
                                                            <FaEnvelopeOpen className="pb-1" /> Buka Undangan
                                                  </Button>
                                        </div>
                              </div>
                    </div>
          );
}

export default Welcome;