import React from 'react';

const UcapanDoa = () => {
          return (
                    <div
                              id="penutup"
                              className="d-flex justify-content-center align-items-top pt-4"
                              style={{ backgroundColor: '#e0d7ca', backgroundPosition: 'center', marginTop: '-2px' }}
                    >
                              <div className="container text-center bg-light pb-3 rounded-4 py-3" data-aos="fade-up">
                                        <div data-aos="fade-down" className="mb-1 pt-3 px-3">
                                                  <h6 className="font-esthetic-tegak font-color-brown">
                                                            Merupakan suatu kebahagiaan dan kehormatan bagi kami, apabila Bapak/Ibu/Saudara/i, berkenan hadir dan memberikan do'a restu kepada kami.
                                                  </h6>
                                                  <h1 className="font-esthetic font-color-brown" style={{ fontWeight: 'bold', fontSize: '28pt' }}>
                                                            Sita & Khisby
                                                  </h1>
                                        </div>
                                        <div className="font-esthetic-tegak font-color-brown" style={{ paddingBottom: '100px' }}>
                                                  &copy; 2024 <a href="https://www.khisoft.id" target="_blank" rel="noreferrer" className='text-decoration-none font-color-brown '>Made with ❤️ <span className='font-esthetic font-color-brown'>Sita & Khisby</span></a>
                                        </div>
                              </div>
                    </div>
          );
}

export default UcapanDoa;