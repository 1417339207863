import React, { useEffect, useState, useRef } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { FaPaperPlane } from 'react-icons/fa6';
import './style.css';
import { toast } from 'react-toastify';

const UcapanDoa = () => {
          const [items, setItems] = useState([]);
          const [isPopupOpen, setIsPopupOpen] = useState(false);
          const [name, setName] = useState('');
          const [message, setMessage] = useState('');
          const containerRef = useRef(null);
          const autoScrollTimeoutRef = useRef(null);
          const isAutoScrollingRef = useRef(true);

          const fetchData = async () => {
                    fetch("https://wedding.khisoft.id/api/api.php")
                              .then(response => response.json())
                              .then(data => {
                                        setItems(data);
                              })
                              .catch(error => {
                                        console.error('Error fetching data:', error);
                              });
          };

          useEffect(() => {
                    setItems([
                              {
                                        "id": "1",
                                        "name": null,
                                        "message": "Hai, Khisby & Sita. Selamat menikah, semoga menjadi keluarga yg sakinah mawaddah warahmah",
                                        "created_at": "2024-09-22 16:50:14"
                              },
                              {
                                        "id": "2",
                                        "name": null,
                                        "message": "Selamat menikah, Khisby & Sita! Semoga pernikahan kalian penuh dengan cinta, kebahagiaan, dan keberkahan.",
                                        "created_at": "2024-09-22 16:51:14"
                              },
                              {
                                        "id": "3",
                                        "name": null,
                                        "message": "Khisby & Sita, selamat menikah! Semoga kalian selalu saling mencintai dan mendukung dalam setiap langkah kehidupan.",
                                        "created_at": "2024-09-22 16:52:14"
                              },
                              {
                                        "id": "4",
                                        "name": null,
                                        "message": "Hai, Khisby & Sita. Selamat menikah! Semoga kalian selalu bersama dalam suka dan duka, serta menjadi keluarga yang harmonis.",
                                        "created_at": "2024-09-22 16:54:14"
                              }
                    ])
                    fetchData();
          }, []);

          const handleSend = async () => {
                    const payload = { name, message };
                    try {
                              const response = await fetch('https://wedding.khisoft.id/api/api.php', {
                                        method: 'POST',
                                        headers: {
                                                  'Content-Type': 'application/json',
                                        },
                                        body: JSON.stringify(payload),
                              });
                              setIsPopupOpen(false);
                              setName('');
                              setMessage('');
                              if (response.ok) {
                                        toast.success('Message sent successfully!');

                                        fetchData();
                              } else {
                                        toast.error('Error sending message.');
                              }
                    } catch (error) {
                              toast.error('Error sending message.');
                              console.error('Error sending message:', error);
                    }
          };

          return (
                    <div
                              id="ucapanDoa"
                              className="d-flex justify-content-center pt-4 align-items-center px-4"
                              style={{ backgroundColor: '#e0d7ca', backgroundPosition: 'center', marginTop: '-2px' }}
                    >
                              <div className="container text-center bg-light rounded-4 py-3 col-12" data-aos="fade-up">
                                        <div data-aos="fade-down" className="mb-1 p-3">
                                                  <h1 className="font-esthetic-tegak font-color-brown" style={{ fontWeight: 'bold', fontSize: '28pt' }}>
                                                            Ucapan & Doa
                                                  </h1>
                                                  <h6 className="font-esthetic-tegak font-color-brown">
                                                            Anda bisa memberikan Ucapan & Doa yang dapat Anda bagikan ke semua pengunjung website ini.
                                                  </h6>
                                                  <Button
                                                            variant="light"
                                                            className="mt-3"
                                                            style={{ backgroundColor: 'rgba(184, 183, 165, 1)', color: 'white' }}
                                                            onClick={() => setIsPopupOpen(true)}
                                                  >
                                                            <FaPaperPlane className="pb-1" /> Kirim Ucapan & Do'a
                                                  </Button>
                                                  <div className="clearfix ">
                                                            <div className='row'>
                                                                      <div className='col-md-12 col-sm-12 scrollable-container' ref={containerRef}>
                                                                                {items.map((item, index) => (
                                                                                          <div className="box">
                                                                                                    <p className="font-color-brown">{item.message}</p>
                                                                                                    <div className="ribbon" style={{ backgroundColor: 'rgba(184, 183, 165, 1)', color: 'white' }}>
                                                                                                              {item.name ?? 'Anonim'}
                                                                                                    </div>
                                                                                          </div>
                                                                                ))}
                                                                      </div>
                                                            </div>
                                                  </div>
                                        </div>
                              </div>
                              <Modal show={isPopupOpen} onHide={() => setIsPopupOpen(false)} >
                                        <Modal.Header closeButton style={{ backgroundColor: 'rgba(184, 183, 165, 1)', color: 'white', borderBottom: 'none' }}>
                                                  <Modal.Title>Berikan Ucapan & Do'a</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body style={{ backgroundColor: 'rgba(184, 183, 165, 1)', color: 'white' }}>
                                                  <Form
                                                            onSubmit={(e) => {
                                                                      e.preventDefault();
                                                                      handleSend();
                                                            }}
                                                  >
                                                            <Form.Group controlId="formName" className='mb-2'>
                                                                      <Form.Label>Name (optional):</Form.Label>
                                                                      <Form.Control
                                                                                type="text"
                                                                                value={name}
                                                                                style={{ color: 'rgba(184, 183, 165, 1)', backgroundColor: 'white', border: 'none' }}
                                                                                onChange={(e) => setName(e.target.value)}
                                                                      />
                                                            </Form.Group>
                                                            <Form.Group controlId="formMessage">
                                                                      <Form.Label>Message:</Form.Label>
                                                                      <Form.Control
                                                                                as="textarea"
                                                                                rows={3}
                                                                                value={message}
                                                                                style={{ color: 'rgba(184, 183, 165, 1)', backgroundColor: 'white', border: 'none' }}
                                                                                onChange={(e) => setMessage(e.target.value)}
                                                                                required
                                                                      />
                                                            </Form.Group>
                                                            <Button variant="light" style={{ backgroundColor: 'rgba(184, 183, 165, 1)', color: 'white' }} type="submit" className="mt-3 float-end">
                                                                      <FaPaperPlane className="pb-1" />  Send
                                                            </Button>
                                                  </Form>
                                        </Modal.Body>
                              </Modal>
                    </div>
          );
}

export default UcapanDoa;