import React, { useEffect } from 'react';
import './style.css';
import lightGallery from 'lightgallery';

// import styles
import 'lightgallery/css/lightgallery.css';

const Galeri = () => {

          const images = [
                    process.env.PUBLIC_URL + '/assets/img/foto1.webp',
                    // process.env.PUBLIC_URL + '/assets/img/foto2.webp',
                    process.env.PUBLIC_URL + '/assets/img/foto3.webp',
                    process.env.PUBLIC_URL + '/assets/img/foto4.webp',
          ];

          useEffect(() => {
                    lightGallery(document.getElementById('lightgallery'), {
                              plugins: [],
                              speed: 500,
                              closable: true,
                              escKey: true,
                              showCloseIcon: true,
                              swipeToClose: true,
                              counter: false,
                              subHtmlSelectorRelative: false,
                    });
          }, []);

          return (
                    <div id="tanggal" className=" d-flex justify-content-center pt-4 align-items-center px-4" style={{ backgroundColor: '#e0d7ca', backgroundPosition: 'center', marginTop: '-2px' }}>
                              <div className="container text-center bg-light rounded-4 py-3 col-12">
                                        <div data-aos="fade-down" className='mb-3'>
                                                  <h1 className='font-esthetic-tegak font-color-brown' style={{ fontWeight: 'bold', fontSize: '28pt' }}>Our Momen</h1>
                                                  <h6 className='font-esthetic-tegak font-color-brown'>Dengan penuh kebahagiaan dan cinta, berikut momen-momen indah kami.</h6>
                                        </div>
                                        <div data-aos="fade-up" className='row justify-content-center'>
                                                  <div className='col-11 mx-auto'>
                                                            <div id="lightgallery" className='row justify-content-center'>
                                                                      {images.map((src, index) => (
                                                                                <div key={index} className="col-xs-12 col-sm-6 col-md-4 col-lg-3 mb-4" data-src={src}>
                                                                                          <img src={src} alt="" className="img-thumbnail rounded rounded-4 bg-white shadow-lg" loading="lazy" />
                                                                                </div>

                                                                      ))}
                                                            </div>
                                                  </div>
                                        </div>
                              </div>
                    </div>
          );
}

export default Galeri;