// import NavbarBottom from './components/navbar';
import Welcome from './components/welcome';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import Home from './components/page/home';
import Mempelai from './components/page/mempelai';
import TanggalLokasi from './components/page/tanggallokasi';
import Galeri from './components/page/galeri';
import UcapanDoa from './components/page/ucapandoa';
import Gift from './components/page/gift';
import Penutup from './components/page/penutup';
import { useRef, useState, useEffect } from "react";
import { ToastContainer } from 'react-toastify';


function App() {
  const scrollRef = useRef(null);

  const [buka, setBuka] = useState(false);
  const [audio, setAudio] = useState(true);
  const audioRef = useRef(null);

  const [host, setHost] = useState(process.env.REACT_APP_HOST);
  useEffect(() => {
    setHost(window.location.host);

  }, []);

  useEffect(() => {
    setAudio(true);
    if (audioRef.current) {
      audioRef.current.play();
    }
  }, [buka]);

  useEffect(() => {
    if (audioRef.current) {
      if (audio) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    }
  }, [audio]);


  return (
    <div className="App">
      {
        host !== process.env.REACT_APP_HOST ?
          (
            <div className="text-center vh-100 justify-content-center align-items-center d-flex">
              <h1>Maaf, website ini hanya bisa diakses melalui <a href="https://wedding.khisoft.id">https://wedding.khisoft.id</a>.</h1>
            </div>
          )
          :
          <>
            {
              !buka ?
                <Welcome stateBuka={setBuka} />
                :
                (
                  <>
                    {/* <NavbarBottom /> */}
                    <div>
                      <i
                        aria-hidden="true"
                        className={`fas fa-compact-disc audio-play ${audio ? 'playing' : ''}`}
                        onClick={() => setAudio(!audio)}
                        style={{ fontSize: '2rem', cursor: 'pointer', zIndex: 9999, color: 'black' }}
                      ></i>
                      <audio ref={audioRef} src="https://wedding.khisoft.id/assets/bersamamu.mp3" loop />
                    </div>
                    <ToastContainer hideProgressBar />

                    <div className='snap-scroll' ref={scrollRef}>
                      <Home />
                      <Mempelai />
                      <TanggalLokasi />
                      <Galeri />
                      <Gift />
                      <UcapanDoa />
                      <Penutup />
                    </div>
                  </>
                )
            }
          </>
      }
    </div>
  );
}

export default App;
