import React from 'react';
import { Image } from 'react-bootstrap';
import './style.css'

const Mempelai = () => {

          return (
                    <div id="mempelai" className="position-relative pb-2 pt-4 d-flex justify-content-center align-items-center overflow-hidden" style={{ backgroundColor: '#e0d7ca', backgroundPosition: 'center', marginTop: '-2px' }}>
                              <div className="position-absolute bottom-0 start-0" style={{ marginLeft: '-15px', marginBottom: '-25px' }}>
                                        <Image src={process.env.PUBLIC_URL + '/assets/img/flower2.png'} style={{ width: '150px' }} alt="bunga" />
                              </div>
                              <div className="text-center px-4">
                                        <div data-aos="fade-up" className='bg-light text-shadow-lg px-5 py-3 box-mempelai col-12'>
                                                  <div className="img-crop-square border border-3 border-light mb-4 mx-auto">
                                                            <Image src={process.env.PUBLIC_URL + '/assets/img/sita.webp'} alt="Sita Fara Yunanda" className='border-radius-10' style={{ width: '100%', height: 'auto' }} />
                                                  </div>
                                                  <h1 className='font-color-brown font-esthetic'>Sita Fara Yunanda</h1>
                                                  <h6 className='font-color-brown font-esthetic-tegak'> Putri Pertama dari <br /> Bpk. Joko Sudibyo & Ibu Puji Astuti B </h6>
                                        </div>
                                        <h1 className='font-color-brown font-esthetic-tegak' style={{ margin: '20px 0' }}> & </h1>
                                        <div data-aos="fade-down" className='bg-light text-shadow-lg px-5 py-3 box-mempelai col-12'>
                                                  <div className="img-crop-square border border-3 border-light mb-4 mx-auto">
                                                            <Image src={process.env.PUBLIC_URL + '/assets/img/khisby.webp'} alt="Khisby Al Ghofari" className='border-radius-10' style={{ width: '100%', height: 'auto' }} />
                                                  </div>
                                                  <h1 className='font-color-brown font-esthetic '>Khisby Al Ghofari</h1>
                                                  <h6 className='font-color-brown font-esthetic-tegak  '> Putra Tunggal dari <br /> Bpk. Moch. Effendi & Ibu Himatul Muniroh </h6>
                                        </div>
                              </div>
                    </div>
          );
}

export default Mempelai;