import React from 'react';
import { Image } from 'react-bootstrap';
import { FaCopy } from "react-icons/fa6";
import { toast } from 'react-toastify';

const Gift = () => {

          const copyToClipboard = (norek) => {
                    navigator.clipboard.writeText(norek).then(() => {
                              toast.success(`"${norek}" copied to clipboard`);
                    }).catch(err => {
                              console.error('Failed to copy text: ', err);
                    });
          }

          return (
                    <div id="gift" className="d-flex justify-content-center pt-4 align-items-center px-4" style={{ backgroundColor: '#e0d7ca', backgroundPosition: 'center', marginTop: '-2px' }}>
                              <div className="container text-center bg-light rounded-4 py-3 col-12" data-aos="fade-up">
                                        <div data-aos="fade-down" className='mb-3 p-3'>
                                                  <h1 className='font-esthetic-tegak font-color-brown' style={{ fontWeight: 'bold', fontSize: '28pt' }}>Wedding Gift</h1>
                                                  <h6 className='font-esthetic-tegak font-color-brown'>Do'a restu Anda tentu saja sangat berharga bagi kami. Tetapi apabila berhalangan hadir dan ingin memberikan hadiah bisa melalui salah satu opsi berikut.</h6><br />
                                                  <div className='row g-2 justify-content-center d-flex align-items-stretch'>
                                                            <div data-aos="fade-up" className='col-md-6 col-sm-12 d-flex flex-column'>
                                                                      <div className='bg-light text-shadow-lg rounded-4 p-3 flex-grow-1' style={{ border: '2px solid rgba(184, 183, 165, 1)' }} >
                                                                                <div className="img-crop-square border border-3 border-light mb-4 mx-auto" >
                                                                                          <Image src={process.env.PUBLIC_URL + '/assets/img/bri.png'} alt="BRI" style={{ width: '100%', height: 'auto' }} />
                                                                                </div>
                                                                                <h5 className='font-color-brown font-esthetic-tegak'>No. Rekening:</h5>
                                                                                <h6 className='font-color-brown font-esthetic-tegak'> 634701015941536 <br />A.N Sita Fara Yunanda </h6>
                                                                                <button className="btn btn-light mt-auto" style={{ backgroundColor: 'rgba(184, 183, 165, 1)', color: 'white' }} onClick={() => copyToClipboard('634701015941536')}><FaCopy className="pb-1" /> Salin No. Rekening</button>
                                                                      </div>
                                                            </div>
                                                  </div>
                                        </div>
                              </div>
                    </div>
          );
}

export default Gift;