import React from 'react';
import { FaMapLocation, FaCalendarDays, FaClock } from 'react-icons/fa6';
import { Button, Image } from 'react-bootstrap';
import './style.css'

const TanggalLokasi = () => {
          return (
                    <div id="tanggal" className="position-relative pb-1 pt-4 d-flex justify-content-center align-items-center overflow-hidden px-4" style={{ backgroundColor: '#e0d7ca', backgroundPosition: 'center', marginTop: '-2px' }}>
                              <div className="container text-center bg-light text-shadow-lg px-3 py-3 box-mempelai col-12" data-aos="fade-up">
                                        <div data-aos="fade-down" className='mb-5'>
                                                  <h1 className='font-esthetic-tegak font-color-brown' style={{ fontWeight: 'bold', fontSize: '28pt' }}>Waktu & Tempat</h1>
                                                  <h6 className='font-esthetic-tegak font-color-brown'>Dengan memohon rahmat dan ridho Allah Subhanahu Wa Ta'ala, insyaAllah kami akan menyelenggarakan acara</h6>
                                        </div>
                                        <div data-aos="fade-up" className='bg-opacity-50 text-shadow p-4 border-radius-10 text-center' style={{ backgroundColor: 'rgba(184, 183, 165, 0.7)', fontWeight: 'bold', fontSize: '28pt' }}>
                                                  <Image src={process.env.PUBLIC_URL + '/assets/img/ring.webp'} alt="Ring" style={{ width: '150px', height: 'auto' }} />
                                                  <h2 className='font-esthetic-tegak'>Akad & Resepsi</h2>
                                                  <div class="row text-center font-esthetic-tegak mb-2" style={{ maxWidth: '600px', margin: '0 auto' }}>
                                                            <div class="col-4 d-flex justify-content-center align-items-center" style={{ borderRight: '2px solid white' }}>
                                                                      <h5>Senin</h5>
                                                            </div>
                                                            <div class="col-4 d-flex justify-content-center align-items-center" style={{ borderRight: '2px solid white' }}>
                                                                      <h5>14 <br />Oktober</h5>
                                                            </div>
                                                            <div class="col-4 d-flex justify-content-center align-items-center">
                                                                      <h5>2024</h5>
                                                            </div>
                                                  </div>
                                                  <h6 className='font-esthetic-tegak'><FaClock className="pb-1" /> 08:00 WIB - Selesai</h6>
                                                  <h6 className='font-esthetic-tegak'>Ds. Tanjungrejo RT 10 RW 04, Kec. Madiun, <br />Kab. Madiun, Prov. Jawa Timur</h6>
                                                  <div className="row col-md-6 col-sm-12 m-auto">
                                                            <div className='col-md-6 col-sm-12'>
                                                                      <Button data-aos="fade-right" variant="light" className="shadow rounded-4 aos-animate p-2" onClick={() => { window.open('https://maps.app.goo.gl/3M3pvq3VugxVddmk8', '_blank'); }}>
                                                                                <FaMapLocation className="pb-1" /> Lihat Google Maps
                                                                      </Button>
                                                            </div>
                                                            <div className='col-md-6 col-sm-12'>
                                                                      <Button data-aos="fade-right" variant="light" className="shadow rounded-4 aos-animate p-2" onClick={() => { window.open('https://calendar.google.com/calendar/event?action=TEMPLATE&tmeid=MWRocXNoajdmY3FqdmsxcDZiZ3FkN2hsZnMga2hpc2J5QG0&tmsrc=khisby%40gmail.com', '_blank'); }}>
                                                                                <FaCalendarDays className="pb-1" /> Save Google Calendar
                                                                      </Button>
                                                            </div>
                                                  </div>
                                        </div>
                                        <div data-aos="fade-up" className='bg-opacity-50 mt-4 text-shadow p-4 border-radius-10' style={{ backgroundColor: 'rgba(184, 183, 165, 0.7)', fontWeight: 'bold', fontSize: '28pt' }}>
                                                  <Image src={process.env.PUBLIC_URL + '/assets/img/bouquet.webp'} alt="Bouquet" style={{ width: '150px', height: 'auto' }} />
                                                  <h2 className='font-esthetic-tegak'>Ngunduh Mantu & Resepsi</h2>
                                                  <div class="row text-center font-esthetic-tegak mb-2 h4" style={{ maxWidth: '600px', margin: '0 auto' }}>
                                                            <div class="col-4 d-flex justify-content-center align-items-center" style={{ borderRight: '2px solid white' }}>
                                                                      <h5>Minggu</h5>
                                                            </div>
                                                            <div class="col-4 d-flex justify-content-center align-items-center" style={{ borderRight: '2px solid white' }}>
                                                                      <h5>20 <br />Oktober</h5>
                                                            </div>
                                                            <div class="col-4 d-flex justify-content-center align-items-center">
                                                                      <h5>2024</h5>
                                                            </div>
                                                  </div>
                                                  <h6 className='font-esthetic-tegak'><FaClock className="pb-1" />10:00 WIB - Selesai</h6>
                                                  <h6 className='font-esthetic-tegak'>Ds. Tarik RT 06 RW 01, Kec. Tarik, <br />Kab. Sidoarjo, Prov. Jawa Timur</h6>
                                                  <div className="row col-md-6 col-sm-12 m-auto">
                                                            <div className='col-md-6 col-sm-12'>
                                                                      <Button data-aos="fade-right" variant="light" className="shadow rounded-4 aos-animate p-2" onClick={() => { window.open('https://maps.app.goo.gl/QoSByxhSiQaoDqra9', '_blank'); }}>
                                                                                <FaMapLocation className="pb-1" /> Lihat Google Maps
                                                                      </Button>
                                                            </div>
                                                            <div className='col-md-6 col-sm-12'>
                                                                      <Button data-aos="fade-right" variant="light" className="shadow rounded-4 aos-animate p-2" onClick={() => { window.open('https://calendar.google.com/calendar/event?action=TEMPLATE&tmeid=MnJxcjBuc2pudDBkbmM5OTczOHVhZjhtMDAga2hpc2J5QG0&tmsrc=khisby%40gmail.com', '_blank'); }}>
                                                                                <FaCalendarDays className="pb-1" /> Save Google Calendar
                                                                      </Button>
                                                            </div>
                                                  </div>
                                        </div>

                              </div>
                    </div >
          );
}

export default TanggalLokasi;